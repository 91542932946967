<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="join_complete">
            <div class="h1">위즈니 회원 가입을 진심으로 환영합니다.</div>
            <div class="small">
              회원 가입에 감사드리며 <i>500포인트</i>를 지급해드립니다.
            </div>
            <div class="join_dsc">
              <div class="join_dsc_img">
                <div>
                  <div>
                    <img
                      src="@/assets/static/images/img_trade.svg"
                      alt="안심 거래"
                    />
                    <div>
                      불안한 거래는 No,<br />
                      에스크로 방식의 <i>안심 거래</i>
                    </div>
                  </div>
                  <div>
                    <img
                      src="@/assets/static/images/img_handshake.svg"
                      alt="중재요청"
                    />
                    <div>
                      문제 발생 시,<br />
                      분쟁신청을 통한 <i>중재요청</i>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                판매자도 OK 구매자도 OK<br />
                위즈니에서 낭비없이 합리적으로 서비스를 공유하세요.
              </div>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnMain">메인으로</button>
              <!--              <button class="btn">파티 생성하기</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <nice
      :id="'formNice10'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'regist-done-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
